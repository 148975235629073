import React from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';
import './Header.scss';

const Header = () => {
  return (

    <div className="app__header app__flex">
      <div className="app__header-content">

        <motion.div
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          transition={{ duration: 0.5 }}
          className="app__header-info"
        >
          <h1 className="header-title">Welcome, I am</h1>
          <h2 className="header-name">Cho Shing Cheung</h2>
          <p className="header-description">
            I am a passionate Mathematics and Computer Science student from UCSD, 
            specializing in developing innovative solutions through coding. 
            With experience in Software and Web Development, as well as AI/ML model building, 
            I have worked on a variety of projects, 
            from creating responsive web applications to developing machine learning models for predictive analysis.
          </p>

          <a href="https://www.linkedin.com/in/choshingcheung/" className="header-button" target="_blank" rel="noopener noreferrer">
            Connect with me on Linkedin
          </a>
        </motion.div>
      </div>
    
      <div className="app__header-image">
      </div>

    </div>
  )
}

export default AppWrap(Header, 'home');